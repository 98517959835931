define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["cs", {
    "Add": "Přidat",
    "Bank transfer": "Bankovním převodem",
    "Cancel": "Zrušit",
    "Confirmation": "Potvrzení",
    "DataGrid": {
      "fulltextLabel": "Filtrace",
      "fulltextPlaceholder": "Hledaná fráze"
    },
    "Delete": "Smazat",
    "Deleted": "Smazáno",
    "Edit": "Upravit",
    "Next": "Další",
    "No data": "Žádná data",
    "Previous": "Předchozí",
    "Save": "Uložit",
    "Saved": "Uloženo",
    "Saving": "Ukládám...",
    "Submit": "Odeslat",
    "Today": "Dnes",
    "api": {
      "200": "Uloženo",
      "500": "Nastala chyba: {message}"
    },
    "beauty-pay.cz": "beauty-pay.cz",
    "createdAt": "Vytvořeno",
    "customer": {
      "visitsCount": {
        "label": "{count} {count, plural, one {zakázka} few {zakázky} other {zakázek}}"
      }
    },
    "dateSelect": {
      "date": "den",
      "enabled": "Poskytnout",
      "month": "měsíc",
      "year": "rok"
    },
    "form": {
      "save": "Uložit"
    },
    "formSelect": {
      "searching": "Vyhledávám ..."
    },
    "intervalPicker": {
      "export": "Stáhnout",
      "from": "od",
      "invalidRange": "Datum \"od\" nesmí být po datumu \"do\".",
      "minusMonth": "-1 měsíc",
      "plusMonth": "+1 měsíc",
      "thisDay": "Dnes",
      "thisMonth": "Tento měsíc",
      "thisWeek": "Tento týden",
      "to": "do"
    },
    "layout": {
      "companies": {
        "edit": "Upravit",
        "stats": "Statistiky"
      },
      "login": {
        "header": "beauty-pay.cz administrace",
        "password": "Heslo",
        "submit": "Přihlásit",
        "submitting": "Přihlašuji...",
        "username": "E-mail"
      },
      "notifications": {
        "sumSent": "Odeslaných SMS"
      },
      "sidebar": {
        "branding": "beauty-pay.cz",
        "logout": "Odhlásit"
      },
      "wizard": {
        "header": "Průvodce vytvořením nové firmy"
      }
    },
    "models": {
      "cashbookEntry": {
        "amount": "Částka",
        "isVisit": "Zakázka",
        "kind": "Typ",
        "note": "Poznámka",
        "touchedAt": "Datum"
      },
      "company": {
        "_modelName": "{count, plural, =1 {Firma} =2 {Firmy} other {Firem}}",
        "address": "Adresa",
        "email": "E-mail",
        "expiryDate": "Datum vypršení",
        "facebook": "Facebook URL",
        "facebookTitle": "Jméno na Facebooku",
        "hasGdprEmails": "Odesílat GDPR e-maily při registraci",
        "instagram": "Instagram URL",
        "instagramTitle": "Jméno na Instagramu",
        "invoiceHeader": "Hlavička na účtence",
        "invoiceLogo": "Logo",
        "isInvoicePrintingActive": "Tisknout účtenku",
        "name": "Název",
        "note": "Poznámka",
        "phone": "Telefon",
        "phoneLandline": "Pevná linka",
        "reservationNotificationTemplate": "Šablona upozornění",
        "website": "Web",
        "willNotifyReservations": "Odesílat upozornění o rezervacích"
      },
      "customer": {
        "_modelName": "{count, plural, =1 {Zákazník} =2 {Zákazníci} other {Zákazníků}}",
        "birth": "Datum narození",
        "firstName": "Jméno",
        "fullName": "Jméno",
        "gender": "Pohlaví",
        "genders": {
          "female": "Žena",
          "male": "Muž"
        },
        "isApproved": "Schválený",
        "lastName": "Příjmení",
        "lastVisitDate": "Poslední návštěva",
        "mail": "E-mail",
        "name": "Jméno",
        "note": "Poznámka",
        "phone": "Telefonní číslo",
        "termsConfirmation": "Souhlasím s podmínkami zpracování osobních údajů",
        "visitsCount": "Návštěv"
      },
      "employee": {
        "_modelName": "{count, plural, =1 {Zaměstnanec} =2 {Zaměstnanci} other {Zaměstnanců}}",
        "companyDebt": "Dluh firmy",
        "contactDetails": "Kontaktní údaje",
        "firstName": "Jméno",
        "fullName": "Jméno",
        "lastName": "Příjmení",
        "photoUrl": "Fotografie",
        "shareOfSales": "Podíl z prodejů",
        "shareOfServices": "Podíl ze služeb",
        "sumOfShareServices": "Suma provizí ze služeb",
        "sumOfTips": "Suma dýšek",
        "sumOfWages": "Suma výplat"
      },
      "entity": {
        "_modelName": "{count, plural, =1 {Ekonomická entita} =2 {Ekonomické entity} other {Ekonomických entit}}",
        "accountNumber": "Číslo účtu",
        "businessRegister": "Zápis v obchodním rejstříku",
        "headquarters": "Sídlo společnosti",
        "identificationNumber": "IČO",
        "invoiceHeader": "Patička na účtence",
        "isVatPayer": "Plátce DPH",
        "name": "Název entity",
        "premisesid": "Číslo provozovny",
        "registerid": "Číslo pokladny",
        "sendEet": "Odesílat EET - pouze prodeje",
        "settlementLogo": "Logo na faktuře",
        "vatid": "DIČ",
        "weight": "Váha"
      },
      "invoice": {
        "_modelName": "{count, plural, =1 {Faktura} =2 {Faktury} other {Faktur}}",
        "amount": "Částka",
        "end": "Do",
        "isPaid": "Zaplaceno",
        "note": "Poznámka",
        "sentSms": "SMS",
        "start": "Od"
      },
      "item": {
        "_modelName": "{count, plural, =1 {Položka skladu} =2 {Položky skladu} other {Položek skladu}}",
        "barcode": "Čárový kód",
        "bought": "Naskladněno",
        "dispensed": "Vydáno",
        "dispensingPrice": "Výdejní cena",
        "duration": "Trvání",
        "isActive": "Aktivní",
        "isService": "Služba",
        "lastPurchasePrice": "Poslední nákupní cena",
        "margin": "Marže",
        "name": "Název",
        "remainsBeforeWarning": "Minimálně skladem",
        "sellingPrice": "Prodejní cena",
        "sold": "Prodáno",
        "stock": "Skladem",
        "stockUpdatedAt": "Poslední změna množství",
        "unlimited": "Neomezené množství",
        "warningThreshold": "Minimálně skladem"
      },
      "itemDispense": {
        "_modelName": "{count, plural, =1 {Výdej} =2 {Výdeje} other {Výdejů}}",
        "count": "Kusů",
        "sumPrice": "Cena",
        "unitPrice": "Cena / ks"
      },
      "language": {
        "_modelName": "{count, plural, =1 {Jazyk} =2 {Jazyky} other {Jazyků}}"
      },
      "notification": {
        "_modelName": "Notifikace",
        "message": "Text",
        "notification": "{count, plural, =1 {Notifikace} =2 {Notifikace} other {Notifikací}}",
        "phone": "Telefon",
        "sendAt": "Odesláno",
        "sentSms": "Zpráv",
        "status": "Stav",
        "statusMessage": "Status"
      },
      "payment": {
        "_modelName": "{count, plural, =1 {Platba} =2 {Platby} other {Plateb}}",
        "amount": "Částka",
        "end": "Do",
        "note": "Poznámka",
        "start": "Od"
      },
      "reservation": {
        "_modelName": "{count, plural, other {Rezervace}}",
        "end": "Konec",
        "note": "Poznámka",
        "start": "Začátek",
        "willNotify": "Upozornit"
      },
      "role": {
        "_modelName": "{count, plural, =1 {Role} =2 {Role} other {Rolí}}",
        "description": "Popisek",
        "featureFlags": "Práva",
        "name": "Název"
      },
      "sell": {
        "_modelName": "{count, plural, =1 {Prodej} =2 {Prodeje} other {Prodejů}}",
        "count": "Kusů",
        "discount": "Sleva",
        "item": "Položka",
        "price": "Jed. cena",
        "sum": "Suma"
      },
      "settlement": {
        "_modelName": "{count, plural, =1 {Faktura} =2 {Faktury} other {Faktur}}",
        "accountNumber": "Číslo účtu",
        "contactDetails": "Kontaktní údaje",
        "end": "Do",
        "entityName": "Dodavatel",
        "headquarters": "Sídlo společnosti",
        "identificationNumber": "IČO",
        "invoiceNumber": "Variabilní symbol",
        "issuedAt": "Datum vystavení",
        "methodOfPayment": "Způsob úhrady",
        "note": "Poznámka",
        "payUntil": "Datum splatnosti",
        "settlementLogo": "Logo",
        "start": "Od",
        "sum": "Celkem k platbě s DPH",
        "sumWithoutVat": "Základ daně",
        "taxablePerformance": "Datum zdanitelného plnění",
        "vat": "Sazba DPH",
        "vatAsNumber": "Daň",
        "vatid": "DIČ"
      },
      "settlementItem": {
        "_modelName": "{count, plural, =1 {Položka} =2 {Položky} other {Položek}}",
        "amount": "Množství",
        "name": "Popis",
        "sum": "Celkem s DPH",
        "sumWithoutVat": "Základ daně",
        "unitPriceWithoutVat": "Jednotková cena",
        "vat": "DPH"
      },
      "signup": {
        "_modelName": "{count, plural, =1 {Registrace} =2 {Registrace} other {Registrací}}",
        "discountCode": "Slevový kód",
        "email": "E-mail",
        "employeeCount": "Počet zaměstnanců / kolegů",
        "firstName": "Jméno",
        "identificationNumber": "IČO",
        "lastName": "Příjmení",
        "phone": "Telefon"
      },
      "supply": {
        "_modelName": "{count, plural, =1 {Naskladnění} =2 {Naskladnění} other {Naskladnění}}",
        "createdAt": "Vytvořeno",
        "margin": "Marže",
        "purchasePrice": "Nákupní cena (bez DPH)",
        "quantity": "Množství",
        "vat": "DPH nákupu"
      },
      "user": {
        "_modelName": "{count, plural, =1 {Uživatel} =2 {Uživatelé} other {Uživatelů}}",
        "authenticationToken": "Token",
        "currentSignInAt": "Momentální přihlášení",
        "email": "E-mail",
        "lastSignInAt": "Poslední přihlášení",
        "password": "Heslo",
        "passwordConfirmation": "Potvrzení hesla",
        "signInCount": "Počet přihlášení"
      },
      "vat": {
        "0": "Nulová (0%)",
        "10": "Druhá snížená (10%)",
        "21": "Základní (21%)",
        "_modelName": "DPH"
      },
      "visit": {
        "_modelName": "{count, plural, =1 {Návštěva} =2 {Návštěvy} other {Návštěv}}",
        "amountSales": "Prodeje",
        "amountServices": "Služby",
        "completed": "Uzavřeno",
        "employeeShare": "➗ zaměstnance",
        "employeeShareSales": "➗ z prodeje",
        "employeeShareServices": "➗ za služby",
        "note": "Poznámka",
        "paidByCard": "Zaplaceno kartou",
        "paidByOther": "Zaplaceno jinak",
        "paidInCash": "Zaplaceno v hotovosti",
        "paidInVoucher": "Zaplaceno vouchery",
        "price": "Cena",
        "priceWithTip": "Celková částka i s dýškem",
        "tip": "Dýško"
      },
      "voucher": {
        "_modelName": "{count, plural, =1 {Voucher} =2 {Vouchery} other {Voucherů}}",
        "barcode": "Čárový kód",
        "currentValue": "Zůstatek",
        "name": "Název",
        "note": "Poznámka",
        "sellingPrice": "Prodejní cena",
        "validUntil": "Platnost do"
      },
      "voucherClaim": {
        "_modelName": "{count, plural, =1 {Uplatnění vouchery} =2 {Uplatnění voucherů} other {Uplatnění voucherů}}",
        "amount": "Částka",
        "createdAt": "Vytvořeno"
      },
      "wage": {
        "_modelName": "{count, plural, =1 {Výplata} =2 {Výplaty} other {Výplat}}",
        "amount": "Částka",
        "note": "Poznámka"
      }
    },
    "updatedAt": "Upraveno",
    "validations": {
      "between": "Musí mít mezi {min} a {max} znaky",
      "confirmation": "{description} neodpovídá {on}",
      "email": "Neplatná e-mailová adresa",
      "fromDate": "FROM date must be less than TO date",
      "greaterThan": "TO date must be be greater then FROM date",
      "inclusion": "Musí být vybráno",
      "invalid": "Neplatný formát",
      "lessThan": "FROM date must be less than TO date",
      "notANumber": "Musí být číslo",
      "present": "Prosím vyplňte",
      "toDate": "TO date has to be greater then FROM date"
    }
  }], ["en", {
    "Add": "Add",
    "Cancel": "Cancel",
    "Confirmation": "Confirmation",
    "DataGrid": {
      "fulltextLabel": "Filtration",
      "fulltextPlaceholder": "Searched phrase"
    },
    "Delete": "Delete",
    "Deleted": "Deleted",
    "Edit": "Edit",
    "Next": "Next",
    "No data": "No data",
    "Previous": "Previous",
    "Save": "Impose",
    "Saved": "Saved",
    "Saving": "Saving ...",
    "Today": "Today",
    "api": {
      "200": "Saved",
      "500": "An error occurred: {message}"
    },
    "createdAt": "Created",
    "dateSelect": {
      "date": "the",
      "enabled": "Provide",
      "month": "Moon",
      "year": "year"
    },
    "form": {
      "save": "Impose"
    },
    "intervalPicker": {
      "export": "Download",
      "from": "from",
      "invalidRange": "The \"from\" date cannot be after the \"to\" date.",
      "minusMonth": "-1 month",
      "plusMonth": "+1 month",
      "thisDay": "Today",
      "thisMonth": "This month",
      "thisWeek": "This week",
      "to": "do"
    },
    "models": {
      "cashbookEntry": {
        "amount": "Amount",
        "isVisit": "The order",
        "kind": "Type",
        "note": "Note",
        "touchedAt": "date"
      },
      "company": {
        "_modelName": "{count, plural, =1 {Firma} =2 {Firmy} other {Firem}}",
        "address": "Address",
        "email": "E-mail",
        "expiryDate": "Expiration date",
        "facebook": "Facebook URL",
        "facebookTitle": "Name on Facebook",
        "instagram": "Instagram URL",
        "instagramTitle": "Name on Instagram",
        "invoiceHeader": "Receipt header",
        "invoiceLogo": "Logo",
        "isInvoicePrintingActive": "Print receipt",
        "name": "Name",
        "note": "Note",
        "phone": "Telephone",
        "phoneLandline": "Landline",
        "reservationNotificationTemplate": "Alert template",
        "website": "Web",
        "willNotifyReservations": "Send booking notifications"
      },
      "customer": {
        "_modelName": "Customer",
        "birth": "Birthdate",
        "firstName": "Name",
        "fullName": "Name",
        "gender": "Sex",
        "genders": {
          "female": "A woman",
          "male": "Man"
        },
        "isApproved": "approved",
        "lastName": "Surname",
        "lastVisitDate": "Last visit",
        "mail": "E-mail",
        "name": "Name",
        "note": "Note",
        "phone": "Telephone number",
        "termsConfirmation": "I agree with the conditions of personal data processing",
        "visitsCount": "Visits"
      },
      "employee": {
        "_modelName": "{count, plural, =1 {Employee} =2 {Employees} other {Employees}}",
        "companyDebt": "Company debt",
        "firstName": "Name",
        "lastName": "Surname",
        "photoUrl": "Photo",
        "shareOfSales": "Share of sales",
        "shareOfServices": "Share of services",
        "sumOfShareServices": "Amount of commissions from services",
        "sumOfWages": "Amount of payments"
      },
      "entity": {
        "_modelName": "{count, plural, =1 {Economic Entity} =2 {Economic Entities} other {Economic Entities}}",
        "businessRegister": "Entry in the Commercial Register",
        "headquarters": "Headquarters",
        "identificationNumber": "IČO",
        "invoiceHeader": "Heel on the receipt",
        "isVatPayer": "The payer of VAT",
        "name": "Entity name",
        "premisesid": "Establishment number",
        "registerid": "Cash register number",
        "sendEet": "Send EET - sales only",
        "vatid": "VAT number"
      },
      "invoice": {
        "_modelName": "{count, plural, =1 {Invoice} =2 {Invoices} other {Invoices}}",
        "amount": "Amount",
        "end": "Do",
        "isPaid": "Paid",
        "note": "Note",
        "sentSms": "SMS",
        "start": "From"
      },
      "item": {
        "_modelName": "{count, plural, =1 {Warehouse Item} =2 {Warehouse Items} other {Warehouse Items}}",
        "barcode": "Barcode",
        "bought": "In stock",
        "dispensingPrice": "Delivery price",
        "isActive": "Active",
        "isService": "Service",
        "lastPurchasePrice": "Last purchase price",
        "margin": "Margins",
        "name": "Name",
        "sellingPrice": "Selling price",
        "sold": "Sold",
        "stock": "In stock",
        "stockUpdatedAt": "Last quantity change",
        "unlimited": "Unlimited amount",
        "warningThreshold": "At least in stock"
      },
      "itemDispense": {
        "_modelName": "{count, plural, =1 {Disbursement} =2 {Disbursements} other {Disbursements}}",
        "count": "Pieces",
        "sumPrice": "Price",
        "unitPrice": "Price / Fr."
      },
      "notification": {
        "_modelName": "Notification",
        "message": "Text",
        "notification": "{count, plural, =1 {Notifications} =2 {Notifications} other {Notifications}}",
        "phone": "Telephone",
        "sendAt": "Sent",
        "sentSms": "News",
        "status": "State",
        "statusMessage": "Status"
      },
      "payment": {
        "_modelName": "{count, plural, =1 {Payment} =2 {Payments} other {Payment}}",
        "amount": "Amount",
        "end": "Do",
        "note": "Note",
        "start": "From"
      },
      "reservation": {
        "_modelName": "{count, plural, other {Rezervace}}",
        "end": "The end",
        "note": "Note",
        "start": "Beginning",
        "willNotify": "Alert"
      },
      "sell": {
        "_modelName": "{count, plural, =1 {Order} =2 {Orders} other {Orders}}",
        "count": "Pieces",
        "discount": "Discount",
        "item": "entry",
        "price": "Jed. the price",
        "sum": "Sum"
      },
      "signup": {
        "_modelName": "{count, plural, =1 {Registration} =2 {Registration} other {Registration}}",
        "email": "E-mail",
        "employeeCount": "Number of employees / colleagues",
        "firstName": "Name",
        "identificationNumber": "IČO",
        "lastName": "Surname",
        "phone": "Telephone"
      },
      "supply": {
        "_modelName": "{count, plural, =1 {Storage} =2 {Storage} other {Storage}}",
        "createdAt": "Created",
        "margin": "Margins",
        "purchasePrice": "Purchase price (excluding VAT)",
        "quantity": "Amount",
        "vat": "VAT purchase"
      },
      "user": {
        "_modelName": "{count, plural, =1 {User} =2 {Users} other {Users}}",
        "currentSignInAt": "Current login",
        "email": "E-mail",
        "lastSignInAt": "Last login",
        "password": "Password",
        "passwordConfirmation": "password confirmation",
        "signInCount": "Number of logins"
      },
      "vat": {
        "0": "Zero (0%)",
        "10": "Second reduced (10%)",
        "21": "Basic (21%)",
        "_modelName": "VAT"
      },
      "visit": {
        "_modelName": "{count, plural, =1 {Visits} =2 {Visits} other {Visits}}",
        "amountSales": "Sales",
        "amountServices": "service",
        "completed": "Closed",
        "employeeShare": "➗ employees",
        "employeeShareSales": "➗ from sale",
        "employeeShareServices": "➗ for services",
        "note": "Note",
        "paidByCard": "card",
        "paidByOther": "Other",
        "paidInCash": "Cash",
        "paidInVoucher": "Voucher claim",
        "price": "Price",
        "priceWithTip": "In total, even with a nozzle",
        "tip": "tip"
      },
      "voucher": {
        "barcode": "Barcode",
        "name": "Name",
        "note": "Note",
        "remainingPrice": "Remaining Price",
        "sellingPrice": "Selling Price",
        "validUntil": "Valid Until"
      },
      "wage": {
        "_modelName": "{count, plural, =1 {Payout} =2 {Payouts} other {Payout}}",
        "amount": "Amount",
        "note": "Note"
      }
    },
    "validations": {
      "between": "Must have between {min} and {max} characters",
      "email": "Invalid email address",
      "greaterThan": "TO date must be be greater then FROM date",
      "inclusion": "Must be selected",
      "invalid": "Invalid format",
      "lessThan": "FROM date must be less than TO date",
      "notANumber": "Must be a number",
      "present": "Please fill"
    }
  }]];
});